<template>
  <b-overlay :show="isLoading" rounded="lg" opacity="0.6" spinner-variant="primary">
    <template #overlay>
      <div class="d-flex align-items-center">
        <b-spinner small type="grow" variant="secondary"></b-spinner>
        <b-spinner type="grow" variant="dark"></b-spinner>
        <b-spinner small type="grow" variant="secondary"></b-spinner>
      </div>
    </template>
    <div class="mt-4">
      <i class="fa fa-backward"></i>
      <router-link :to="{ name: AuthorPath.name }"> Authors </router-link>
    </div>
    <b-card :header="'Edit Author (' + title + ')'" header-bg-variant="primary" header-tag="h5"
      header-class="font-weight-bold">
      <b-form @submit="onSubmit">
        <b-row>
          <b-col md="6" lg="4">
            <b-form-group label="Name">
              <b-form-input v-model="form.name" type="text" placeholder="Author Name" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" lg="4">
            <b-form-group label="Slug">
              <b-form-input v-model="form.slug" type="text" placeholder="Author Slug" required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" lg="4">
            <b-form-group label="Birth Date">
              <b-form-datepicker today-button id="birthdate" autocomplete="off"
                v-model="form.birthdate"></b-form-datepicker>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6" lg="4">
            <b-form-group label="First">
              <b-form-input v-model="form.first_name" type="text" placeholder="First Name"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" lg="4">
            <b-form-group label="Last">
              <b-form-input v-model="form.last_name" type="text" placeholder="Last Name"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6" lg="4">
            <b-form-group label="Academic Title">
              <b-form-input v-model="form.academic_title" type="text" placeholder="example: S.H., M.H."></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Description">
              <b-form-textarea id="description" v-model="form.meta" placeholder="Enter something..."
                rows="5"></b-form-textarea>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group>
              <b-form-checkbox v-model="form.is_active" :value="true" :unchecked-value="false">Active
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button class="mx-1" type="submit" variant="primary">Submit</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-card>

      <b-row class="align-items-end">
          <b-col md="4">
            <b-form-group label="Select Image">
              <input type="file" accept="image/*" @change="previewImage" class="form-control-file" id="my-file">
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group>
              <b-button variant="primary" pill size="sm" @click="onUpload">upload</b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <div class="border p-2 mt-3">
              <p>Preview Here:</p>
              <b-row>
                <b-col md="6">
                  <template v-if="form.imagesrc">
                    <img :src="form.imagesrc" class="img-fluid" style="width: 30%" />
                  </template>
                </b-col>
                <b-col md="6">
                  <template v-if="form.preview" >
                    <img :src="form.preview" class="img-fluid" style="width: 30%"/>
                    <p class="mb-0">file name: {{ form.image.name }}</p>
                    <p class="mb-0">size: {{  Math.ceil(form.image.size/1024) }} KB</p>
                  </template>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { AuthorPath } from '../../router/content';
import constant from "../../store/constant";
export default {
  data() {
    return {
      id: null,
      title: '',
      form: {
        name: null,
        slug: null,
        is_active: false,
        birthdate: null,
        first_name: null,
        last_name: null,
        academic_title: null,
        meta: null,
        preview: null,
        image: null,
        imagesrc: null,
      },
      AuthorPath,
    }
  },
  created() {
    const id = this.$route.params.id;
    this.id = id;
    this.fetchAuthorByID({ id });
  },
  computed: {
    ...mapGetters('author', ['author']),
    ...mapState({
      isLoading: (state) => state.author.isLoading,
      isError: (state) => state.author.isError,
      errorMessage: (state) => state.author.errorMessage,
      successMessage: (state) => state.author.successMessage,
    }),
  },
  watch: {
    errorMessage: function (message) {
      if (!this.errorMessage) return;
      this.messageAlert('error', message, 5000);
    },
    successMessage: function (message) {
      if (!this.successMessage) return;
      this.messageAlert('success', message);
      this.$router.push(this.AuthorPath);
    },
    author: function (data) {
      if (!data) return;
      this.title = data.name;
      this.form.name = data.name;
      this.form.slug = data.slug;
      this.form.is_active = data.is_active;
      this.form.birthdate = data.birthdate;
      this.form.first_name = data.first_name;
      this.form.last_name = data.last_name;
      this.form.academic_title = data.academic_title;
      this.form.meta = data.meta;
      this.form.imagesrc =data.profile_pic_url ? constant.IMG_URL_STATIC_AUTHOR.URL + data.profile_pic_url : null;
    },
  },
  methods: {
    ...mapActions('author', [
      'updateAuthor',
      'fetchAuthorByID',
      'uploadImage',
    ]),
    onSubmit(event) {
      event.preventDefault();
      this.form.id = this.id;
      this.updateAuthor(this.form);
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.form.preview = e.target.result;
        }
        this.form.image=input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    onUpload(e) {
      e.preventDefault();
      if (!this.form.image) {
        this.messageAlert('error', 'Please select file to upload');
        return;
      }
      this.uploadImage({
        id: this.id,
        image: this.form.image,
      });
    },
    messageAlert(icon, title, timer = 3000) {
      this.$swal({
        toast: "true",
        position: "top-end",
        icon,
        title,
        showConfirmButton: false,
        timer,
        timerProgressBar: true,
      });
    },
  }
}
</script>